export default {
  main_text: 'Nu ezita să-ți realizezi visul acum.',
  home: 'Acasa',
  contacts: 'Sugestie',
  cabinet_pro: 'Cabinet PRO',
  my_profile: 'Profilul meu',
  login: 'Logare',
  pro: 'PRO',
  log_in: 'Intrare',
  mini_text: 'Vă rugăm frumos să completați datele de mai jos:',
  credit: 'Credit',
  legal_notice: 'Mențiuni legale',
  questions: 'Întrebări și răspunsuri',
  about_us: 'Despre noi',
  aim: 'Scopul',
  total: 'Suma',
  period: 'Perioada',
  how_working: 'Cum funcționează ?',
  apply: 'Aplică',
  credits: 'Credite',
  fast: 'rapid',
  transparent: 'transparent',
  correct: 'corect',
  customer_reviews: 'Recenziile clienților',
  user_1_name: 'Vasile Cazacu',
  user_1_description: 'Inițial am crezut că este un site oarecare, apoi am vizionat videoclipul “Cum funcționează?” și mi-am zis, hai să \n' +
      'Încerc să aplic, tocmai mi se defectase mașina, am fost contactat de un expert financiar, am avut o scurtă \n' +
      'Discuție și mi-a dat banii. Acuma folosesc site-ul unitedcredit.md ori decîte ori am nevoie de un credit.',
  user_2_name: 'Ludmila Ciobanu',   
  user_2_description: 'Eram in căutarea unui credit pentru a-mi cumpăra un aspirator. Am început să mă interesez\n' +
      'Și să caut pe internet ce companii oferă ce-l mai bun preț la creditare. Am fost suprins să descoper\n' +
      'Site-ul unitedcredit.md. Nici nu a mai fost nevoie să mai caut, totul am găsit pe site, ușor, simplu și accesibil.',
  user_3_name: 'Ilie Prunici',
  user_3_description: 'Sunt student la facultate și mi-am dorit demult să am un nou lătop. Ceea ce era prin magazine nu-mi puteam \n' +
      'Permite. Am apelat la mai multe companii de creditare, din toate pe unitedcredit au fost ce-i mai rapizi. \n' +
      'Dimineața am obținut creditul, iar seara deja mă bucuram de noul meu laptop. Mersi unitedcredit.md pentru \n' +
      'Suportul oferit.',
  user_4_name:'Ghenadie Postolache',
  user_4_description: 'Eu sunt o persoană cu dizabilități, îmi este dificil să mă deplasez prin oraș, iar pentru mine, \n' +
      'tot ce poate fi online este o ușurare. Am aplicat cu sfială la un credit pe unitedcredit, în scurt timp am fost contactat\n' +
      'De un expert creditar, mulțumesc pentru sfaturile oferite, am găsit oferte de credit omenești.',
  our_partners: 'Partenerii noștri',
  our_partners_text: 'Împreună am realizat un serviciu comod de a fi mai aproape de clientii noștri. Acum poți fi mai aproape de ei:',
  deals: 'Oferte',
  search: 'Cauta',
  effective_interest: 'Dobinda efectiva',
  total_amount: 'Suma totala',
  monthly_payment: 'Plata lunară',
  calculator: 'Calculator',
  out_of: 'din',
  offers: 'oferte',
  calculator_text: 'Calculator de credite',
  fill_data: 'Completează datele',
  fill_data_text_sub_one: 'Odată cu introducerea datelor, "Calculatorul" va reînnoi oferi automat.',
  fill_data_text_sub_two: 'Odată ce ați găsit oferta dorită, apăsați butonul "Aplică" pentru a o procesa.',
  credit_amount: 'Suma creditului',
  date_of_grand: 'Data Acordarii',
  date_of_payment : 'Data primei plăți',
  percentage_rate: 'Rată procentuală',
  ofert: 'Oferta',
  granting_commission: 'Comision de acordare',
  management_fee: 'Comision de administrare',
  amount_of_interest: 'Suma dobânzii',
  total_for_payment: 'Total spre plată',
  one_credit: 'Un credit',
  recenzii_clienti :'Recenzile clienților',
  error_message_form: ' Vă rugăm frumos completați toate câmpurile !',
  personal_info: 'Informație personală',
  name: 'Nume',
  first_name: 'Prenume',
  personal_goods: 'Bunuri personale',
  phone: 'Telefon',
  info_text_step_one: 'În scopul completării și validării cît mai rapide a cerererii dvs. de creditare va îndemnăm să completați cît mai atent și onest cimpurile cu date dvs. Vă mulțumim pentru cooperare și că a-ți ales unitedcredit.md!',
  next: 'Inainte',
  back: 'Inapoi',
  birth_and_address: 'Data nașterii și adresa',
  birthday: 'Data nasterii',
  place_of_birth: 'Locul nasterii',
  id_cart: 'Adresa din buletin',
  billing_address: 'Adresa de facturare',
  number_chlidrens: 'Numarul copiilor',
  family_situation: 'Situația Familiară',
  family_situation_celibatar: 'Celibatar',
  family_situation_casatorit: 'Căsătorit',
  family_situation_divortat: 'Divorțat (ă)',
  family_situation_concubinaj: 'Concubinaj',
  family_situation_vaduv: 'Văduv (ă)',  
  workplace: 'Locul de munca',
  function_work: 'Functia detinuta',
  time_contract: 'Durata contractului',
  situation_contract_nedeterminat : 'Nedeterminată',
  situation_contract_determinat : 'Determinată',
  work_experience: 'Stagiu de lucru',
  work_experience_in_years: 'Stagiu de lucru, perioada în ani',
  not_official: 'Nu sunt angajat oficial.',
  info_text_step_four: 'În acest caz ve-ți trece la pasul urmator fără a completa câmpurile anterioare.',
  income: 'Venituri',
  bunuri_proprietate : 'Bunuri în proprietate',
  and : 'și',
  expenses: 'Cheltuieli',
  pesonal_salary: 'Salariu personal',
  another_member_family_salary: 'Salariu membrilor de familie',
  another_cash: 'Alte venituri',
  perosnal_goods: 'Bunuri personale (ex: Automobil)',
  imobil_goods: 'Imobile (ex: casa / teren)',
  total_sum_month: 'Cheltuieli lunare',
  credit_history: 'Rechizite bancare',
  no_credit_history: 'Nu am credite active.',
  name_organization: 'Denumire organizatiei',
  term: 'Termen',
  balance: 'Sold rămas',
  currency: 'Valuta',
  months: 'luni',
  months_label:'Luni',
  remaining_balance: 'Sold rămas',
  info_text_step_six: 'Selectează această bifă în cazul cind solicitantul nu deține cont bancar. În acest caz ve-ți trece la pasul urmator fără a completa câmpurile cu rechezitele bancare.',
  card_account: 'Cont de card',
  bank_account: 'Cont bancar',
  full_name: 'Numele complet',
  mm: 'mm',
  dd: 'zz',
  card: 'card',
  dont_have_card: 'Nu dețin nici un cont bancar',
  info_text_step_seven: 'În acest caz ve-ți trece la pasul urmator fără a completa câmpurile anterioare.',
  password: 'Parola',
  confirm_password: 'Confirma parola',
  read_and_agree: 'Am citit și sunt de acord',
  info_text_step_nine: 'Concensemintul petru datele cu caracter personal si verificare la biroul istoriilor de credite',
  bot_info_text : 'Pentru informatii suplimentare va rugam sa ne conectati la adresa de email',
  success: 'Succes',
  info_text_step_success: 'Forma dumneavoastră este înregistrată și deja se procesează...',
  credits_active: 'Credite active',
  credits_active_yes : 'Da, sunt',
  credits_active_no : 'Nu, sunt',
  saved_offers: 'Oferte salvate',
  archive: 'Arhiva',
  bank_supplies: 'Rechizite bancare',
  exit: 'Iesire',
  save: 'Salveaza',
  purpose_credit: 'Scopul creditului',
  total_debt: 'Datoria totala',
  next_payment: 'Următoarea achitare',
  monthly_installments: 'Rata lunară',
  remaining_rates: 'Rate rămase',
  bank_acc: 'Rechezite bancare',
  suggestion_field_title: '*Vreau să vin cu o sugestie ...',
  suggestion_field_description : 'Descrierea',
  suggestion_field_name: 'Numele',
  suggestion_field_surname : 'Prenumele',
  suggestion_field_mail_answer: 'Astept raspuns prin e-mail',
  suggestion_field_mail: 'E-mail',
  suggestion_label_question_about_client_untiedcredit : 'Sunteti un client UnitedCredit?',
  suggestion_text_about_protection_data_client_untiedcredit : 'UnitedCredit are grija de protecția datelor cu caracter personal, de aceea vă informam că datele cu caracter personal furnizate de către DVS. vor fi prelucrate în procesul de examinare și soluționare a petițiilor. Ele vor fi accesibile persoanelor responsabile desemnate din cadrul Companiei. Informații complete despre prelucrarea datelor găsiți aici.',
  suggestion_checkbox_confirm_platform_agreement_confidential_policy : 'Confirm că am citit, am înțeles și sunt de acord cu politica de confidențialitate.',
  suggestion_notification_form_apply_decline:'Daca nu doriți că datele Dvs. să fie prelucrate de către UnitedCredit, vă rugam să evitați completarea formularului de contract de mai sus.',
  suggestion_button_click_send: 'Trimite',
  rate_mdl: 'lei',
  region: 'Regiunea',
  each_of: 'Cîte',
  calculator_rate: 'Calculator de rate',
  label_no_offers : 'Nu sunt oferte. Mai încercați și alte posibilități!',
  no_activ_credits : 'Nu am credite active.',
  home_text_button_become_partner : 'Devin-o partener',
  home_button_text_partner_submit : 'Trimite',
  home_button_text_partner_close_modal : 'Închide fereastră',
  home_partner_text_notification : 'Cererea a fost transmisă spre examinare!', 
  home_partner_text_about_mail_notification : 'În scurt timp veți primi notificare pe mailul indicat!',
  home_partner_text_thanks_choose_unitedcredit : 'Vă mulțumim că ați ales UNITEDCREDIT!',
  home_partner_text_agree_policy : ' Sunt deacord cu temeile site-ului, prelucrarea datelor persoanale și confirm că am citit, am înțeles și sunt deacord cu politică de confidențialitate',
  home_partner_text_welcome_unitedcredit : 'Bun venit pe platforma United Credit',
  home_partner_text_please_fill_form :'Pentru a deveni Partener - Va rugăm',
  home_partner_text_fill_data_form_below : 'să completați datele de mai jos:',
  calculator_button_text_calculeaza :'Calculează'
  
}
