import rootReducer from "./reducers";
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable no-underscore-dangle */
export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk)),
);
/* eslint-enable */
