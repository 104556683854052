import en from './en.js';
import ro from './ro.js';
import ru from './ru.js';

export default  {
  en: {
    ...en
  },
  ro: {
    ...ro
  },
  ru: {
    ...ru
  }
};
