import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

const Home = lazy(() => import('./pages/Home/Home'));
const Contacts = lazy(() => import('./pages/Contacts/Contacts'));
const Form = lazy(() => import('./pages/Form/Form'));
const Oferte = lazy(() => import('./pages/Oferte/Oferte'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Calculator = lazy(() => import('./pages/Calculator/Calculator'));
//const Pro = lazy(() => import('./pages/Pro/Pro'));

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/contacts" component={Contacts}/>
        <Route path="/form" component={Form}/>
        <Route path="/oferte" component={Oferte}/>
        <Route path="/profile" component={Profile}/>
        <Route path="/calculator" component={Calculator}/>
        {/*<Route path="/pro" component={Pro}/>*/}
      </Switch>
    </Suspense>
  </Router>
);

export default App;
