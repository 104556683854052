export default {
  main_text: "Don't hesitate to make your dream come true now.",
  home: 'Home',
  contacts: 'Suggestions',
  cabinet_pro: 'Profile PRO',
  my_profile: 'My profile',
  login: 'Login',
  pro: 'PRO',
  log_in: 'Log in',
  mini_text: 'Please fill in the data below:',
  credit: 'Credit',
  legal_notice: 'Legal notice',
  questions: 'Questions and answers',
  about_us: 'About us',
  aim: 'Goal',
  total: 'Sum',
  period: 'Period',
  how_working: 'How does it work ?',
  apply: 'Apply',
  credits: 'Credits',
  fast: 'Quick',
  transparent: 'transparent',
  correct: 'correct',
  customer_reviews: 'Cusomer review',
  user_1_name: 'Vasile Cazacu',
  user_1_description: 'At first I thought it was a site, then I watched the video "How does it work?" and I said to myself, come on\n' +
      'I try to apply, my car had just broken down, I was contacted by a financial expert, I had a short\n' +
      'Talk and he gave me the money. Now I use the unitedcredit.md website whenever I need a loan.',
  user_2_name: 'Ludmila Ciobanu',
  user_2_description: ' I was looking for a loan to buy a vacuum cleaner. I started to get interested\n' +
      'And search the internet for which companies offer the best price for credit. I was surprised to find out\n' +
      'The unitedcredit.md website. I didn\'t even have to search anymore, I found everything on the site, easy, simple and accessible.',
  user_3_name: 'Ilie Prunici',
  user_3_description: ' I am a college student and I have long wanted a new set. What was in the shops I couldn\'t\n' +
      'Enable. I turned to several lending companies, all of them on unitedcredit were the fastest.\n' +
      'In the morning I got the loan, and in the evening I was already enjoying my new laptop. Thanks unitedcredit.md for\n' +
      'Support provided.',
  user_4_name:'Ghenadie Postolache',
  user_4_description: ' I am a person with disabilities, it is difficult for me to move around the city, and for me,\n' +
      'everything that can be online is a relief. I shyly applied for a loan on unitedcredit, I was contacted shortly\n' +
      'By a credit expert, thanks for the advice, I found human credit offers.',
  our_partners: 'Partenerii noștri',
  our_partners_text: 'Together we have created a convenient service to be closer to our customers. Now you can be closer to them:',
  deals: 'Deals',
  search: 'Search',
  effective_interest: 'Effective interest',
  total_amount: 'Total amount',
  monthly_payment: 'Monthly payment',
  calculator: 'Calculator',
  out_of: 'from',
  offers: 'Offers',
  calculator_text: 'Credit calculator',
  fill_data: 'Fill in the data',
  fill_data_text_sub_one: 'Once the data is entered, the "Computer" will automatically renew the offer.',
  fill_data_text_sub_two: 'Once you have found the desired offer, click the "Apply" button to process it.',
  credit_amount: 'Credit amount',
  date_of_grand: 'Date of grand',
  date_of_payment : 'Date of payment',
  percentage_rate: 'Percentage rate',
  ofert: 'Offer',
  granting_commission: 'Grant Commission',
  management_fee: 'Management fee',
  amount_of_interest: 'Interest amount',
  total_for_payment: 'Total for payment',
  one_credit: 'One credit',
  recenzii_clienti:'Clients feedback',
  error_message_form: ' Please fill in all fields !',
  personal_info: 'Personal information',
  name: 'Name',
  first_name: 'First_name',
  personal_goods: 'Personal goods',
  phone: 'Phone',
  info_text_step_one: 'In order to complete and validate your credit application as quickly as possible, we urge you to fill in the fields with your data as carefully and honestly as possible. Thank you for your cooperation and for choosing unitedcredit.md!',
  next: 'Next',
  back: 'Back',
  birth_and_address: 'Date of birth and address',
  birthday: 'Date of birth',
  place_of_birth: 'Place of birth',
  id_cart: 'Bulletin address',
  billing_address: 'Billing Address',
  number_chlidrens: 'Number of children',
  family_situation: 'Family Situation',
  family_situation_celibatar: 'Unmarried',
  family_situation_casatorit: 'Married',
  family_situation_divortat: 'Divorced',
  family_situation_concubinaj: 'Сoncubinage',
  family_situation_vaduv: 'Widower',  
  workplace: 'Workplace',
  function_work: 'Function held',
  time_contract: 'Contract duration',
  situation_contract_nedeterminat : 'Unlimited',
  situation_contract_determinat : 'Determined',
  work_experience: 'Work experience',
  work_experience_in_years : 'Work experience, period in years',
  not_official: 'I\'m not an official employee.',
  info_text_step_four: 'In this case you will move on to the next step without filling in the previous fields.',
  income: 'Income',
  bunuri_proprietate : 'Properties',
  and : 'and',
  expenses: 'Expenses',
  pesonal_salary: 'Personal salary',
  another_member_family_salary: 'Salary of other family members',
  another_cash: 'Other income',
  perosnal_goods: 'Personal property (ex: Car)',
  imobil_goods: 'Real estate (eg real estate / rent)',
  total_sum_month: 'Total amount of monthly expenses',
  credit_history: 'Credit history',
  name_organization: 'Name of the organization',
  term: 'Term',
  balance: 'Balance',
  currency: 'Currency',
  months: 'months',
  months_label:'Months',
  remaining_balance: 'Remaining balance',
  info_text_step_six: 'Select this check if the applicant does not have a bank account. In this case, you will move on to the next step without filling in the fields with the bank details.',
  card_account: 'Card account',
  bank_account: 'Bank account',
  full_name: 'Full name',
  mm: 'mm',
  dd: 'dd',
  card: 'card',
  dont_have_card: "I don't have a bank account",
  info_text_step_seven: 'In this case you will move on to the next step without filling in the previous fields.',
  password: 'Password',
  confirm_password: 'Confirm password',
  read_and_agree: 'I have read and agree to the consent',
  info_text_step_nine: 'Consensus for personal data and verification at the credit history office',
  bot_info_text : 'For additional information please connect us to the email address',
  success: 'Success',
  info_text_step_success: 'Your form is registered and is already being processed...',
  credits_active: 'Active credits',
  credits_active_yes : 'Yes, I have',
  credits_active_no : 'No, I do not have',
  saved_offers: 'Saved offers',
  archive: 'Archive',
  bank_supplies: 'Bank supplies',
  exit: 'Exit',
  save: 'Save',
  purpose_credit: 'Credit purpose',
  total_debt: 'Total debt',
  next_payment: 'Next payment',
  monthly_installments: 'Monthly rate',
  remaining_rates: 'Remaining rates',
  suggestion_field_title: '* I want to come up with a suggestion ...',
  suggestion_field_description: 'Description',
  suggestion_field_name: 'Name',
  suggestion_field_surname: 'First name',
  suggestion_field_mail_answer: 'I am waiting for an email reply',
  suggestion_field_mail: 'Email',
  suggestion_label_question_about_client_untiedcredit: 'Are you a UnitedCredit customer?',
  suggestion_text_about_protection_data_client_untiedcredit: 'UnitedCredit takes care of the protection of your personal data, so we inform you that the personal data provided by you. will be processed in the process of examining and resolving petitions. They will be accessible to the designated responsible persons within the Company. Complete information on data processing can be found here. ',
  suggestion_checkbox_confirm_platform_agreement_confidential_policy: 'I confirm that I have read, understood and agree to the privacy policy.',
  suggestion_notification_form_apply_decline: 'If you do not want your data to be processed by UnitedCredit, please avoid completing the contract form above.',
  suggestion_button_click_send: 'Submit',
  rate_mdl: 'moldavian leu',
  region: 'Region',
  each_of: 'Each of',
  calculator_rate: 'Loan calculator',
  label_no_offers : 'There are no deals. Try again a new combination!',
  no_activ_credits : 'I have no active loans.',
  home_text_button_become_partner : 'Become a partner',
  home_button_text_partner_submit : 'Send',
  home_button_text_partner_close_modal : 'Close',
  home_partner_text_notification : 'The application was submitted for examination!', 
  home_partner_text_about_mail_notification : 'You will receive a notification by e-mail shortly!',
  home_partner_text_thanks_choose_unitedcredit : 'Thank you for choosing UNITEDCREDIT!',
  home_partner_text_agree_policy : ' I agree with the themes of the site, the processing of personal data and I confirm that I have read, understood and agree with the privacy policy',
  home_partner_text_welcome_unitedcredit : 'Welcome to the platform United Credit',
  home_partner_text_please_fill_form :'To Become a Partner - Please',
  home_partner_text_fill_data_form_below : 'fill in the data below:',
  calculator_button_text_calculeaza :'Calculate'
}
