export default {
  main_text: 'Не стесняйтесь, чтобы ваша мечта стала реальностью.',
  home: 'Домой',
  /*contacts: 'Контакты',*/
  contacts: 'Заявка',
  cabinet_pro: 'Кабинет PRO',
  my_profile: 'Мой профиль',
  login: 'Логин',
  pro: 'PRO',
  log_in: 'Вход',
  mini_text: 'Пожалуйста, заполните данные ниже: ',
  credit: 'Кредит',
  legal_notice: 'Официальное уведомление',
  questions: 'Вопросы и ответы',
  about_us: 'О нас',
  aim: 'Цель',
  total: 'Сумма',
  period: 'Период',
  how_working: 'Как это работает ?',
  apply: 'Применить',
  credits: 'Кредиты',
  fast: 'быстрый',
  transparent: 'прозрачный',
  correct: 'правильно',
  customer_reviews: 'Отзывы клиентов',
  user_1_name: 'Василе Казаку',
  user_1_description: 'Сначала подумал, что это обычный сайт, потом посмотрел видео "Как это работает?" и я сказал себе, давай\n' +
      'попробую подать заявку, моя машина только что сломалась, со мной связался финансовый эксперт, у меня была короткий\n' +
      'розговор и он перечислил мне деньги. Теперь я использую сайт unitedcredit.md, когда мне нужна ссуда.',
  user_2_name: 'Людмила Чобану',
  user_2_description: 'Искал ссуду на покупку пылесоса. Я начал интересоваться\n' +
      'И поискал  в Интернете, какие компании предлагают лучшую цену за кредит. Я был удивлен, узнав про\n' +
      'Сайт unitedcredit.md. Мне даже не пришлось больше искать, я нашел все на сайте, легко, просто и доступно.',
  user_3_name: 'Илие Пруничи',
  user_3_description: 'Я студент колледжа и давно хотел новый ноутбук. Что было в магазинах, я не мог себе позволить.\n' +
      'Я обратился в несколько кредитных компаний, из всех они на unitedcredit были самыми быстрыми.\n' +
      'Утром получил ссуду, а вечером уже наслаждался новым ноутбуком. Спасибо unitedcredit.md за\n' +
      'Предоставляется поддержка.',
  user_4_name:'Геннадий Постолаче',
  user_4_description: 'Я человек с ограниченными возможностями, мне сложно передвигаться по городу, и мне,\n' +
      'все, что может быть в сети, приносит облегчение. Я робко подал заявку на получение кредита на unitedcredit, вскоре со мной связался Кредитный эксперт, спасибо за совет, я нашел предложения по кредитованию людей.',
  our_partners: 'Наши партнеры',
  our_partners_text: 'Вместе мы создали удобный сервис, чтобы быть ближе к нашим клиентам. Теперь вы можете быть ближе к ним:',
  deals: 'Предложения',
  search: 'Поиск',
  effective_interest: 'Эффективный интерес',
  total_amount: 'Общая сумма',
  monthly_payment: 'Ежемесячный платеж',
  calculator: 'Калькулятор',
  out_of: 'от',
  offers: 'Предложения',
  calculator_text: 'Кредитный калькулятор',
  fill_data: 'Заполнить данные',
  fill_data_text_sub_one: 'После ввода данных  Компьютер  автоматически обновит предложение.',
  fill_data_text_sub_two: 'Как только вы нашли желаемое предложение, нажмите кнопку  Применить  для его обработки.',
  credit_amount: 'Сумма кредита',
  date_of_grand: 'Дата предоставления',
  date_of_payment : 'Дата первого платежа',
  percentage_rate: 'Процентная ставка',
  ofert: 'Предложение',
  granting_commission: 'Комиссия по гранту',
  management_fee: 'Плата за управление',
  amount_of_interest: 'Сумма процентов',
  total_for_payment: 'Итого к оплате',
  one_credit: 'Один кредит',
  recenzii_clienti :'Отзывы клиентов',
  error_message_form: ' Пожалуйста, заполните все поля !',
  personal_info: 'Личная информация',
  name: 'Имя ',
  first_name: 'Фамилия',
  personal_goods: 'Личные товары',
  phone: 'Телефон',
  info_text_step_one: 'Чтобы заполнить и проверить вашу заявку на кредит как можно быстрее, мы настоятельно рекомендуем вам заполнять поля своими данными как можно более внимательно и честно.Спасибо за сотрудничество и за то, что вы выбрали unitedcredit.md!',
  next: 'Вперёд',
  back: 'Назад',
  birth_and_address: 'Дата рождения и адрес',
  birthday: 'Дата рождения',
  place_of_birth: 'Место рождения',
  id_cart: 'Адрес бюллетеня',
  billing_address: 'Адрес для выставления счета',
  number_chlidrens: 'Количество детей',
  family_situation: 'Семейное положение',
  family_situation_celibatar: 'Холостяк',
  family_situation_casatorit: 'Женат',
  family_situation_divortat: 'Разведен (на)',
  family_situation_concubinaj: 'Сожительство',
  family_situation_vaduv: 'Вдов (ец/а)',  
  workplace: 'Рабочее место',
  function_work: 'Функция удержана',
  time_contract: 'Срок действия контракта',
  situation_contract_nedeterminat : 'Неопределенный',
  situation_contract_determinat : 'Определенный',
  work_experience: 'Рабочий стаж',
  work_experience_in_years : 'Рабочий стаж, период в годах',
  not_official: 'Я не официальный работник.',
  info_text_step_four: 'В этом случае вы перейдете к следующему шагу без заполнения предыдущих полей.',
  income: 'Доходы',
  bunuri_proprietate : 'Свойство',
  and : 'и',
  expenses: 'Расходы',  
  pesonal_salary: 'Личная зарплата',
  another_member_family_salary: 'Зарплата других членов семьи',
  another_cash: 'Прочие доходы',
  perosnal_goods: 'Личная собственность (например, автомобиль)',
  imobil_goods: 'Недвижимость (например, недвижимость / аренда)',
  total_sum_month: 'Общая сумма ежемесячных расходов',
  credit_history: 'Кредитная история',
  name_organization: 'Название организации',
  term: 'Cрок',
  balance: 'Баланс',
  currency: 'Валюта',
  months: 'месяцы',
  months_label:'Месяцы',
  remaining_balance: 'Остаток',
  info_text_step_six: 'Установите этот флажок, если у заявителя нет банковского счета. В этом случае вы перейдете к следующему шагу без заполнения полей с банковскими реквизитами.',
  card_account: 'Карточный счет',
  bank_account: 'Банковский счет',
  full_name: 'Полное имя',
  mm: 'мм',
  dd: 'дд',
  card: 'карта',
  dont_have_card: 'У меня нет банковского счета',
  info_text_step_seven: 'В этом случае вы перейдете к следующему шагу без заполнения предыдущих полей.',
  password: 'Пароль',
  confirm_password: 'Подтвердите пароль',
  read_and_agree: 'Я прочитал и согласен',
  info_text_step_nine: 'Консенсус для персональных данных и проверки в офисе кредитной истории',
  bot_info_text : 'Для получения дополнительной информации, пожалуйста, свяжитесь с нами по адресу электронной почты',
  success: 'Успех',
  info_text_step_success: 'Ваша форма зарегистрирована и уже обрабатывается...',
  credits_active: 'Активные кредиты',
  credits_active_yes : 'Да, у меня есть',
  credits_active_no : 'Нет, у меня нету',
  saved_offers: 'Сохраненные предложения',
  archive: 'Архив',
  bank_supplies: 'Банковские принадлежности',
  exit: 'Выход',
  save: 'Сохранить',
  purpose_credit: 'Цель кредита',
  total_debt: 'Общая задолженность',
  next_payment: 'Следующий платеж',
  monthly_installments: 'Ежемесячная ставка',
  remaining_rates: 'Оставшиеся ставки',
  suggestion_field_title: '* Я хочу предложить предложение ...',
  suggestion_field_description: 'Описание',
  suggestion_field_name: 'Имя',
  suggestion_field_surname: 'Имя',
  suggestion_field_mail_answer: "Я жду ответа по электронной почте",
  suggestion_field_mail: 'Электронная почта',
  suggestion_label_question_about_client_untiedcredit: 'Вы клиент UnitedCredit?',
  suggestion_text_about_protection_data_client_untiedcredit: 'UnitedCredit заботится о защите ваших личных данных, поэтому мы сообщаем вам, что личные данные предоставлены вами. будут обрабатываться в процессе рассмотрения и разрешения обращений. Они будут доступны для назначенных ответственных лиц в Компании. Полную информацию об обработке данных можно найти здесь. ',
  suggestion_checkbox_confirm_platform_agreement_confidential_policy: 'Я подтверждаю, что прочитал, понял и согласен с политикой конфиденциальности.',
  suggestion_notification_form_apply_decline: 'Если вы не хотите, чтобы ваши данные обрабатывались UnitedCredit, не заполняйте форму контракта выше.',
  suggestion_button_click_send: "Отправить",
  rate_mdl: 'лей',
  region: 'Регион',
  each_of: 'По',
  calculator_rate: 'Кредитный калькулятор',
  label_no_offers : 'Нет предложений. Попробуйте другие возможности!',
  no_activ_credits : 'У меня нет активных кредитов.',
  home_text_button_become_partner : 'Стань партнером',
  home_button_text_partner_submit : 'Отправить',
  home_button_text_partner_close_modal : 'Закрой окно',
  home_partner_text_notification : 'Заявка подана на рассмотрение!', 
  home_partner_text_about_mail_notification : 'Вскоре вы получите уведомление по электронной почте!',
  home_partner_text_thanks_choose_unitedcredit : 'Спасибо, что выбрали UNITEDCREDIT!',
  home_partner_text_agree_policy : ' Я согласен с тематикой сайта, обработкой персональных данных и подтверждаю, что прочитал, понял и согласен с политикой конфиденциальности',
  home_partner_text_welcome_unitedcredit : 'Добро пожаловать на платформу United Credit',
  home_partner_text_please_fill_form :'Чтобы стать партнером - пожалуйста',
  home_partner_text_fill_data_form_below : 'заполните данные ниже:',
  calculator_button_text_calculeaza :'Расчет'
}
