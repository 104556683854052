import { SET_AUTH } from "../actionTypes";

const app = (state = {
  isAuth: false,
  user: null,
}, action) => {
  switch (action.type) {
    case SET_AUTH: {
      console.info(action);
      return  {...state, isAuth: action.payload.isAuth}
    }
    default: {
      return state;
    }
  }
};

export default app;
