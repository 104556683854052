import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import { Provider } from 'react-redux'
import store from './redux/store'
import translations from './translations/index.js'


require('dotenv').config();

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('ro'));

axios.defaults.baseURL = `${process.env.REACT_APP_API_KEY}/api`;
//axios.defaults.headers.common['Authorization'] = '';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
